// src/components/Header.js
import React from 'react';
import { AppBar, Toolbar, Typography, Button, Container, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <AppBar position="static" color="primary">
      <Toolbar>
        <Container maxWidth="lg">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" component="div">
             AJ Group of Companies
            </Typography>
            <Box>
              <Button component={Link} to="/" color="inherit">
                Home
              </Button>
              <Button component={Link} to="/real-estate" color="inherit">
                Real Estate
              </Button>
              <Button component={Link} to="/tution" color="inherit">
                Tution
              </Button>
              <Button component={Link} to="/account" color="inherit">
                Accounting 
              </Button>
              <Button component={Link} to="/contact" color="inherit">
                Contact
              </Button>
            </Box>
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
