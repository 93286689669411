import React from 'react';
import { Box, Container, Typography, Grid, IconButton, Link } from '@mui/material';
import { Email, Phone } from '@mui/icons-material';

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: 'black', color: 'white', py: 4 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="center" alignItems="flex-start">
          {/* Contact Us */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom align="center">
              Contact Us
            </Typography>
            <Grid container spacing={2} direction="column" alignItems="center">
              <Grid item>
                <IconButton color="inherit" href="tel:+919380376834" sx={{ mr: 1 }}>
                  <Phone />
                </IconButton>
                <Typography variant="body2" color="inherit" sx={{ display: 'inline' }}>
                  +91 93803 76834
                </Typography>
              </Grid>
              <Grid item>
                <IconButton color="inherit" href="mailto:srigangadeviandco@gmail.com" sx={{ mr: 1 }}>
                  <Email />
                </IconButton>
                <Typography variant="body2" color="inherit" sx={{ display: 'inline' }}>
                  srigangadeviandco@gmail.com
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="inherit" align="center">
                  Office #21/9, Gaddige Bogadhi Main Road,<br />
                  Maratikyathanahalli, Mysore-570026
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Footer Bottom */}
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Typography variant="body2" color="inherit">
            © AJ Elite Group Of Company 2024, All Rights Reserved. Design & Developed By{' '}
            <Link href="http://www.vinyasinfotech.com" color="inherit" underline="hover">
              www.vinyasinfotech.com
            </Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
