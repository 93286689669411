import React from 'react';
import { Container, Typography, Box, Paper, Grid } from '@mui/material';

const About = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 5, mb: 5 }}>
      {/* Header */}
      <Typography variant="h2" component="h1" gutterBottom align="center">
        About Us
      </Typography>
      <Typography variant="body1" paragraph align="center">
        Learn more about our mission and vision. We are committed to delivering exceptional experiences and solutions through our innovative approach.
      </Typography>

      {/* Mission and Vision Section */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h5" component="h2" gutterBottom>
              Our Mission
            </Typography>
            <Typography variant="body1" paragraph>
              Our mission is to provide cutting-edge solutions that drive progress and make a positive impact on our customers and the community. We are dedicated to innovation, excellence, and customer satisfaction.
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h5" component="h2" gutterBottom>
              Our Vision
            </Typography>
            <Typography variant="body1" paragraph>
              Our vision is to be a global leader in our industry, setting new standards for quality and innovation. We aim to inspire and empower through our work, fostering a culture of creativity and excellence.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default About;
