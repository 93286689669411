import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  TextField,
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import ScheduleIcon from '@mui/icons-material/Schedule';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

// Styled components
const MapContainer = styled('div')({
  position: 'relative',
  paddingTop: '56.25%',
  height: 0,
  overflow: 'hidden',
  borderRadius: '12px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  marginBottom: '2rem',
  '& iframe': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
});

const ContactCard = styled(Card)(({ theme }) => ({
  borderRadius: '12px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  marginBottom: theme.spacing(4),
}));

const ContactUs = () => {
  const [form, setForm] = useState({ name: '', email: '', phone: '', message: '' });
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);
    try {
      await axios.post('http://localhost:5000/send', form);
      setSent(true);
      setForm({ name: '', email: '', phone: '', message: '' });
    } catch (err) {
      setError('Failed to send message');
    } finally {
      setSending(false);
    }
  };

  return (
    <Container maxWidth="lg">
      {/* Google Map */}
      <Box sx={{ mb: 5 }}>
        <Typography variant="h5" component="h3" gutterBottom align="center">
          Find Us on the Map
        </Typography>
        <MapContainer>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31184.717952703333!2d76.5651933!3d12.309743!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baf7bdb5e601da5%3A0x1af7628d68ee0bae!2sAJ%20Elite%20Accounting%20%26%20Financial%20Services!5e0!3m2!1skn!2sin!4v1724518691890!5m2!1skn!2sin"
            title="Google Maps Location"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </MapContainer>
      </Box>

      {/* Contact Form and Information */}
      <Box sx={{ my: 5 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <ContactCard elevation={6}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Contact Us
                </Typography>
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    margin="normal"
                    name="name"
                    value={form.name}
                    onChange={handleChange}
                    required
                  />
                  <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    type="email"
                    name="email"
                    value={form.email}
                    onChange={handleChange}
                    required
                  />
                  <TextField
                    fullWidth
                    label="Phone Number"
                    variant="outlined"
                    margin="normal"
                    type="tel"
                    name="phone"
                    value={form.phone}
                    onChange={handleChange}
                    required
                  />
                  <TextField
                    fullWidth
                    label="Message"
                    variant="outlined"
                    margin="normal"
                    multiline
                    rows={4}
                    name="message"
                    value={form.message}
                    onChange={handleChange}
                    required
                  />
                  {error && <Typography color="error">{error}</Typography>}
                  {sent && <Typography color="success">Message sent successfully!</Typography>}
                  <CardActions>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ mx: 'auto', display: 'block' }}
                      disabled={sending}
                    >
                      {sending ? 'Sending...' : 'Send Message'}
                    </Button>
                  </CardActions>
                </form>
              </CardContent>
            </ContactCard>
          </Grid>

          <Grid item xs={12} md={6}>
            <ContactCard elevation={6}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Contact Information
                </Typography>
                <Typography variant="body1" paragraph>
                  <strong>Office Address:</strong><br />
                  Office #21/9, Gaddige Bogadhi Main Road,<br />
                  Maratikyathanahalli, Mysore-570026
                </Typography>
                <Typography variant="body1" paragraph>
                  <strong>Phone:</strong><br />
                  <PhoneIcon sx={{ verticalAlign: 'middle', marginRight: '0.5rem' }} />
                  +91 9380376834
                </Typography>
                <Typography variant="body1" paragraph>
                  <strong>Email:</strong><br />
                  <EmailIcon sx={{ verticalAlign: 'middle', marginRight: '0.5rem' }} />
                  srigangadeviandco@gmail.com
                </Typography>
              </CardContent>
            </ContactCard>
          </Grid>
        </Grid>
      </Box>

      {/* Footer */}
      <Box sx={{ py: 2, textAlign: 'center', backgroundColor: '#f5f5f5', borderTop: '1px solid #e0e0e0' }}>
        <Typography variant="body2" color="text.secondary">
          © {new Date().getFullYear()} AJ Elite Group Of Company. All rights reserved.
        </Typography>
      </Box>
    </Container>
  );
};

export default ContactUs;
