// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About'; // Assuming you have an About component
import Contact from './components/Contact'; // Assuming you have a Contact component
import RealEstate from './components/RealEstate';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tuition from './components/Tuition';
import AcccountServicess from './components/AccountingServices';


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/real-estate" element={<RealEstate />} />
          <Route path="/tution" element={<Tuition />} />
          <Route path="/account" element={<AcccountServicess />} />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
