import React from 'react';
import { Grid, Card, CardContent, Typography, Box, Container, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoneyIcon from '@mui/icons-material/Money';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AssessmentIcon from '@mui/icons-material/Assessment';

const services = [
  {
    id: 1,
    title: 'Tax Auditing',
    description: 'Comprehensive tax audits to ensure compliance with regulations and identify potential savings.',
    icon: <AssessmentIcon fontSize="large" color="primary" />,
    benefits: [
      'Detailed financial reviews',
      'Error identification and correction',
      'Compliance with legal requirements',
    ],
  },
  {
    id: 2,
    title: 'Tax Return Preparation',
    description: 'Expert preparation and filing of tax returns, maximizing your deductions and minimizing liabilities.',
    icon: <AccountBalanceIcon fontSize="large" color="secondary" />,
    benefits: [
      'Accurate and timely filing',
      'Maximizing tax deductions',
      'Year-round tax planning advice',
    ],
  },
  {
    id: 3,
    title: 'Payroll Services',
    description: 'End-to-end payroll management, ensuring timely payments and accurate tax withholdings.',
    icon: <MoneyIcon fontSize="large" color="success" />,
    benefits: [
      'Automated payroll processing',
      'Compliance with payroll tax laws',
      'Direct deposit and detailed pay stubs',
    ],
  },
  {
    id: 4,
    title: 'Financial Planning and Consulting',
    description: 'Strategic financial planning to help businesses and individuals achieve long-term goals.',
    icon: <CheckCircleIcon fontSize="large" color="info" />,
    benefits: [
      'Personalized financial strategies',
      'Investment and savings advice',
      'Debt management and budgeting plans',
    ],
  },
];

const AccountingServices = () => {
  return (
    <Container sx={{ padding: '2rem' }}>
      {/* Header Section */}
      <Box sx={{ textAlign: 'center', marginBottom: '2rem' }}>
        <Typography variant="h4" gutterBottom>
          Professional Accounting Services
        </Typography>
        <Typography variant="h6" color="textSecondary">
          Helping you manage your finances with confidence and accuracy.
        </Typography>
      </Box>

      {/* Services List */}
      <Grid container spacing={4}>
        {services.map((service) => (
          <Grid item xs={12} sm={6} md={6} key={service.id}>
            <Card>
              <CardContent>
                <Box sx={{ textAlign: 'center', marginBottom: '1rem' }}>
                  {service.icon}
                </Box>
                <Typography variant="h5" gutterBottom align="center">
                  {service.title}
                </Typography>
                <Typography variant="body1" color="textSecondary" align="center">
                  {service.description}
                </Typography>
                <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                  Key Benefits:
                </Typography>
                <List>
                  {service.benefits.map((benefit, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <CheckCircleIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={benefit} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default AccountingServices;
