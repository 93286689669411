import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import { Box, IconButton, Dialog, DialogContent, Typography, Grid, Container, Card, CardContent, CardMedia } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { styled, useTheme } from '@mui/system';
import image2 from '../assets/images/image2.jpg';
import image3 from '../assets/images/image3.jpg';
import image4 from '../assets/images/image4.jpg';
import founderImage from '../assets/images/image1.jpg';

// Array of images for the slider
const images = [
  { src: image2, alt: 'Tutorials' },
  { src: image3, alt: 'Accounting' },
  { src: image4, alt: 'Professional Services' }
];

// Styled components for better control
const StyledSlider = styled(Slider)(({ theme }) => ({
  '& .slick-slide img': {
    borderRadius: '12px',
    boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.3)',
  },
  '& .slick-dots': {
    bottom: '20px',
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '16px',
  boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0px 12px 32px rgba(0, 0, 0, 0.3)',
  },
}));

const FounderCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '16px',
  boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0px 12px 32px rgba(0, 0, 0, 0.3)',
  },
}));

const Home = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const sliderRef = useRef(null);

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  // Handle previous slide
  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  // Handle next slide
  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  // Open modal with selected image
  const handleClickImage = (src) => {
    setCurrentImage(src);
    setOpen(true);
  };

  // Close modal
  const handleClose = () => {
    setOpen(false);
    setCurrentImage(null);
  };

  return (
    <Container maxWidth="lg">
      {/* Image Slider */}
      <Box sx={{ position: 'relative', width: '100%', height: 'auto', mb: 8, mt: 4 }}>
        <StyledSlider ref={sliderRef} {...settings}>
          {images.map((image, index) => (
            <Box
              key={index}
              sx={{
                height: '500px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1)), url(${image.src}) center/cover no-repeat`,
                borderRadius: '16px',
              }}
            >
              <Box sx={{ width: '100%', height: '100%', cursor: 'pointer' }} onClick={() => handleClickImage(image.src)}>
                <img
                  src={image.src}
                  alt={image.alt}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '16px',
                    opacity: 0.7,
                  }}
                />
              </Box>
            </Box>
          ))}
        </StyledSlider>
        <IconButton
          sx={{
            position: 'absolute',
            top: '50%',
            left: 16,
            transform: 'translateY(-50%)',
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
            },
          }}
          onClick={handlePrev}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <IconButton
          sx={{
            position: 'absolute',
            top: '50%',
            right: 16,
            transform: 'translateY(-50%)',
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
            },
          }}
          onClick={handleNext}
        >
          <ArrowForwardIosIcon />
        </IconButton>

        {/* Modal for image preview */}
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogContent sx={{ p: 0 }}>
            {currentImage && (
              <img
                src={currentImage}
                alt="Preview"
                style={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'contain',
                }}
              />
            )}
          </DialogContent>
        </Dialog>
      </Box>

      {/* Who We Are */}
      <Box sx={{ mb: 8, backgroundColor: theme.palette.grey[200], py: 4, px: 2, borderRadius: '8px' }}>
        <Typography variant="h4" component="h2" gutterBottom align="center" sx={{ mb: 4, color: theme.palette.primary.main }}>
          Who We Are
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph align="center">
          At AJ Group of Companies, we are more than just a company; we are a collective of passionate individuals united by a common goal – to create inspiring and enduring spaces. Our team, with years of collective experience, brings unparalleled expertise to every project. We are founded on core values of integrity, transparency, and customer-centricity, ensuring that every client's journey with us is marked by trust and satisfaction. Committed to quality, innovation, and dedication, we proudly stand as your premier partner for real estate, construction, financial solutions (including accounting, statutory compliance, and company secretary services), and educational coaching and student development.
        </Typography>
      </Box>

      {/* Real Estate */}
      <Box sx={{ mb: 8 }}>
        <Typography variant="h4" component="h2" gutterBottom align="center" sx={{ mb: 4, color: theme.palette.primary.main }}>
          Welcome to AJ Elite Group Of Properties
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph align="center">
          AJ Elite Properties & Constructions specializes in construction services, land development, real estate transactions, and legal documentation. Our focus is on creating enduring structures and remarkable designs that will stand the test of time. As a turnkey construction company in Mysore, we offer quality-driven deliverables including housing construction, commercial buildings, residential apartments, and interior decorations.
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" component="h3" gutterBottom>
                  Building Constructions
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  We provide high-quality construction services for both residential and commercial properties.
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" component="h3" gutterBottom>
                  Property Management
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Efficient management of your properties with a focus on maintenance and tenant satisfaction.
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" component="h3" gutterBottom>
                  Rental Bookings
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Hassle-free rental bookings for residential and commercial spaces.
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
        </Grid>
      </Box>

      {/* Financial Accounting and Consulting Services */}
      <Box sx={{ mb: 8, backgroundColor: theme.palette.grey[200], py: 4, px: 2, borderRadius: '8px' }}>
        <Typography variant="h4" component="h2" gutterBottom align="center" sx={{ mb: 4, color: theme.palette.primary.main }}>
          AJ Elite Group of Companies Financial Accounting and Consulting Services
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph align="center">
          Established in 2014, our accounting and financial services team consists of professional accountants, accounting technicians, and seasoned consultants. We provide a comprehensive, one-stop approach to all your financial needs.
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" component="h3" gutterBottom>
                  Accounting and Auditing
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Thorough accounting and auditing services to ensure compliance and accuracy.
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" component="h3" gutterBottom>
                  Tax Filing
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Expert assistance with individual and company tax returns.
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" component="h3" gutterBottom>
                  Legal Compliance
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Services for company registration, statutory compliance, and more.
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
        </Grid>
      </Box>

      {/* Academy */}
      <Box sx={{ mb: 8 }}>
        <Typography variant="h4" component="h2" gutterBottom align="center" sx={{ mb: 4, color: theme.palette.primary.main }}>
          AJ Elite Group Of Academy
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph align="center">
          Founded in 2014, our academy offers courses designed to be simple and easy to follow, meeting the growing needs of learners. Our content promotes side-by-side practice, ensuring active learning. We help school students visualize difficult concepts in Math and Science while engaging them in activities like soft skills, personality development programs, meditation, and more to enhance their growth.
        </Typography>
      </Box>
    </Container>
  );
};

export default Home;
