import React, { useState } from 'react';
import { Grid, Card, CardContent, CardMedia, Typography, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, Container } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import image1 from '../assets/images/image1.jpg'; // Replace with actual images
import image2 from '../assets/images/image2.jpg'; // Replace with actual images
import image3 from '../assets/images/image3.jpg'; // Replace with actual images
import image4 from '../assets/images/image4.jpg'; // Replace with actual images
import image5 from '../assets/images/image4.jpg'; // Replace with actual images

// Dummy Data for Properties
const properties = [
  {
    id: 1,
    title: 'Luxury Apartment in Downtown',
    price: '₹1,20,00,000',
    description: '3 beds • 2 baths • 1800 sq ft',
    details: 'This luxury apartment is located in the heart of downtown with easy access to shopping and dining. Features include hardwood floors, modern appliances, and a spacious balcony.',
    type: 'Apartment',
    size: '1800 sq ft',
    location: '123 Main Street, Downtown, Bengaluru',
    yearBuilt: 2015,
    amenities: ['Hardwood Floors', 'Modern Appliances', 'Spacious Balcony', 'Gym', 'Swimming Pool'],
    image: image1
  },
  {
    id: 2,
    title: 'Cozy Family House',
    price: '₹85,00,000',
    description: '4 beds • 3 baths • 2500 sq ft',
    details: 'A perfect family home with a large backyard, updated kitchen, and a finished basement. Located in a quiet neighborhood with great schools and parks nearby.',
    type: 'House',
    size: '2500 sq ft',
    location: '456 Elm Street, Suburbia, Pune',
    yearBuilt: 2010,
    amenities: ['Large Backyard', 'Updated Kitchen', 'Finished Basement', 'Two-Car Garage', 'Near Schools'],
    image: image2
  },
  {
    id: 3,
    title: 'Modern Condo with City View',
    price: '₹60,00,000',
    description: '2 beds • 2 baths • 1200 sq ft',
    details: 'A stylish condo offering stunning city views, open floor plan, and high-end finishes. Conveniently located near public transit and popular attractions.',
    type: 'Condo',
    size: '1200 sq ft',
    location: '789 City Avenue, Metropolis, Delhi',
    yearBuilt: 2018,
    amenities: ['City Views', 'Open Floor Plan', 'High-End Finishes', 'Gym', 'Rooftop Deck'],
    image: image3
  },
  {
    id: 4,
    title: 'Prime Land for Development',
    price: '₹25,00,000',
    description: '10 acres of land',
    details: 'A prime piece of land ready for development. Located in a growing area with great potential for residential or commercial use.',
    type: 'Land',
    size: '10 acres',
    location: '101 Development Lane, Growth City, Hyderabad',
    yearBuilt: null,
    amenities: ['Prime Location', 'Development Potential', 'Growing Area', 'Zoned for Mixed Use'],
    image: image4
  },
  {
    id: 5,
    title: 'Commercial Building in Industrial Area',
    price: '₹30,00,000',
    description: '5000 sq ft commercial space',
    details: 'A large commercial building located in an industrial area, ideal for warehouses or business operations. Close to major transport links.',
    type: 'Commercial Building',
    size: '5000 sq ft',
    location: '202 Industrial Road, Industrial Park, Chennai',
    yearBuilt: 2005,
    amenities: ['Industrial Area', 'Large Space', 'Transport Links', 'Loading Dock', 'High Ceilings'],
    image: image5
  }
];

const RealEstate = () => {
  const [open, setOpen] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);

  const handleClickOpen = (property) => {
    setSelectedProperty(property);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedProperty(null);
  };

  return (
    <Container sx={{ padding: '2rem' }}>
      {/* Company Information */}
      <Box sx={{ marginBottom: '2rem', textAlign: 'center' }}>
        <Typography variant="h3" gutterBottom>
          Welcome to Elite Real Estate
        </Typography>
        <Typography variant="h6" color="textSecondary" gutterBottom>
          Your trusted partner in buying, selling, and renting properties.
        </Typography>
        <Typography variant="body1" color="textSecondary">
          With over 20 years of experience, we offer personalized services to help you find your dream home or investment property. Our team is committed to guiding you through every step of the process with professionalism and dedication.
        </Typography>
      </Box>

      {/* YouTube Button */}
      <Box sx={{ textAlign: 'center', marginBottom: '2rem' }}>
        <Button
          variant="contained"
          color="error"
          startIcon={<YouTubeIcon />}
          href="https://www.youtube.com/watch?v=hSsaV0J9mKs" // Replace with your YouTube video URL
          target="_blank"
          rel="noopener noreferrer"
          sx={{ borderRadius: '50px', padding: '0.5rem 2rem' }}
        >
          Watch Property Tour
        </Button>
      </Box>

      {/* Display Properties */}
      <Grid container spacing={4}>
        {properties.map((property) => (
          <Grid item xs={12} sm={6} md={4} key={property.id}>
            <Card
              onClick={() => handleClickOpen(property)}
              sx={{
                cursor: 'pointer',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.03)',
                  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                },
                borderRadius: 2
              }}
            >
              <CardMedia
                component="img"
                height="200"
                image={property.image}
                alt={property.title}
                sx={{ objectFit: 'cover' }}
              />
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {property.title}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                  {property.price}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {property.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Property Details Dialog */}
      {selectedProperty && (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogTitle>{selectedProperty.title}</DialogTitle>
          <DialogContent dividers>
            <CardMedia
              component="img"
              height="300"
              image={selectedProperty.image}
              alt={selectedProperty.title}
              sx={{ marginBottom: '1rem', objectFit: 'cover' }}
            />
            <Typography variant="h6" gutterBottom>
              {selectedProperty.price}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Description:</strong> {selectedProperty.description}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              <strong>Type:</strong> {selectedProperty.type}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              <strong>Size:</strong> {selectedProperty.size}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              <strong>Location:</strong> {selectedProperty.location}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              <strong>Year Built:</strong> {selectedProperty.yearBuilt || 'N/A'}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              <strong>Amenities:</strong>
              <ul>
                {selectedProperty.amenities.map((amenity, index) => (
                  <li key={index}>{amenity}</li>
                ))}
              </ul>
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {selectedProperty.details}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
};

export default RealEstate;
